import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { Form, Input, Select, DatePicker, Button } from 'antd';
import { withRouter } from 'react-router-dom'
import moment from 'moment';
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

import MapComponent from "./mapComponent";

import '../../HelperComponents/helperComponents.css'
import './bookingService.css'
import './checkOut.css'
import FormItem from "antd/lib/form/FormItem";


const defaultPosition = {
    lat: 27.9878,
    lng: 86.925
};

const customId = "custom-id-yes";

class BookingServiceForm extends React.Component {
    // Constructor 
    constructor(props) {
        super(props);
        this.myRef = React.createRef()  

        //set plate codes
        var AbuDhabi = []
        var AbuDhabiCodes = []
        var Ajman = []
        var AjmanCodes = []
        var Dubai = []
        var DubaiCodes = []
        var Fujairah = []
        var FujairahCodes = []
        var RasAlKhaimah = []
        var RasAlKhaimahCodes = []
        var Sharjah = []
        var SharjahCodes = []
        var UmmAlQuwain = []
        var UmmAlQuwainCodes = []
        var plateCodes = []
        // setting codes for Abu Dhabi
        AbuDhabi.push("1")
        for(var i = 4; i < 18; i++){
            AbuDhabi.push(i.toString())
        }
        AbuDhabi.push("50")
        for (var i = 0; i < AbuDhabi.length; i++){
            var element = {id: i+1, code: AbuDhabi[i]}
            AbuDhabiCodes.push(element)
        }
        // end
        plateCodes.push(AbuDhabiCodes)
        // setting codes for Ajman
        Ajman = ["A","B","C","D","E","H"]
        for (var i = 0; i < Ajman.length; i++){
            var element = {id: i+1, code: Ajman[i]}
            AjmanCodes.push(element)
        }
        // end
        plateCodes.push(AjmanCodes)
        // setting codes for Dubai
        Dubai = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA"]
        for (var i = 0; i < Dubai.length; i++){
            var element = {id: i+1, code: Dubai[i]}
            DubaiCodes.push(element)
        }
        // end
        plateCodes.push(DubaiCodes)
        // setting codes for Fujairah
        Fujairah = ["A","B","C","D","E","F","G","K","M","P","R","S","T"]
        for (var i = 0; i < Fujairah.length; i++){
            var element = {id: i+1, code: Fujairah[i]}
            FujairahCodes.push(element)
        }
        // end
        plateCodes.push(FujairahCodes)
        // setting codes for Ras Al Khaimah
        RasAlKhaimah = ["A","C","D","I","K","M","N","S","V","Y"]
        for (var i = 0; i < RasAlKhaimah.length; i++){
            var element = {id: i+1, code: RasAlKhaimah[i]}
            RasAlKhaimahCodes.push(element)
        }
        // end
        plateCodes.push(RasAlKhaimahCodes)
        // setting codes for Sharjah
        Sharjah = ["1","2","3"]
        for (var i = 0; i < Sharjah.length; i++){
            var element = {id: i+1, code: Sharjah[i]}
            SharjahCodes.push(element)
        }
        // end
        plateCodes.push(SharjahCodes)
        // setting codes for Umm Al Quwain
        UmmAlQuwain = ["A","B","C","D","E","F","G","H","I","X"]
        for (var i = 0; i < UmmAlQuwain.length; i++){
            var element = {id: i+1, code: UmmAlQuwain[i]}
            UmmAlQuwainCodes.push(element)
        }
        // end
        plateCodes.push(UmmAlQuwainCodes)        
        
        this.state = {
            disableFields: false,
            brands: [],
            models: [],
            services:
            [
                {id: 1, title: "Oil And Filter"},
                {id: 2, title: "Fuel System Cleaning"},
                {id: 3, title: "AC Service"},
                {id: 4, title: "Pre-owned Car Checkup"},
                {id: 5, title: "Tyres service"},
                {id: 6, title: "Battery Replacement"},
                {id: 7, title: "Brakes Service"},
                {id: 8, title: 	"Car Detailing"},
            ],
            sentServiceId: this.props.history.location.id,
            Emirates: 
            [
                "Abu Dhabi",
                "Ajman","Dubai",
                "Fujairah",
                "Ras Al Khaimah",
                "Sharjah",
                "Umm Al Quwain"
            ],
            plateEmirates: 
            [
                {id: 1, title: "Abu Dhabi"}, 
                {id: 2, title: "Ajman"}, 
                {id: 3, title: "Dubai"}, 
                {id: 4, title: "Fujairah"}, 
                {id: 5, title: "Ras Al Khaimah"}, 
                {id: 6, title: "Sharjah"}, 
                {id: 7, title: "Umm Al Quwain"},
            ],
            allPlateCodes: plateCodes,
            // 1-> service, 2-> pack, 3-> offer.
            bookingType: 1,
            // identify if the customer is a guest or a signed in customer
            guest: true,
            // to be sent on continue pressed
            firstName: "",
            lastName: "",
            phoneNumber: "",
            selectedCarBrand: "",
            selectedCarModel: "",
            selectedServices: this.props.history.location.id,
            plateSelectedEmirate: "",
            plateSelectedEmirateId: "",
            plateSelectedCode: "",
            plateSelectedCodes: [],
            plateNumber: "",
            lat: null,
            lng: null,
            referralCode: "",
            selectedPlace: "place 01",
            displayError: "",
            continueSuccess: false,
            commnets: "",
            availableBookingTimes: [],
            team: null,
            teamAvailabilitiesResponse: null,
            selectedDate: "",
            selectedTime: "",
            verificationCode: "",
            orderSummary: [],
            orderSummaryTotal: 0,
            packId: null,
            dataIsLoaded: false,
            isFinishClickable: true,
        };
        
    }

    componentDidMount() {
        // get user info if available
        fetch("/userInfo")
        .then((res) => res.json())
        .then((json) => {
            if (json.message === "No authenticated user") {
                this.setState({
                    guest: true,
                })
            } else {
                this.setState({
                    firstName: json.first_name,
                    lastName: json.last_name,
                    phoneNumber: json.phone_number,
                    guest: false,
                    dataIsLoaded: true
                })
            }
        })
        .then(() => {
            if (this.props.history.location.disable !== undefined && this.props.history.location.disable) {
                if (this.props.history.location.bookingType == 3) {
                    this.setState({
                        disableFields: true,
                        bookingType: 3,
                        selectedServices: this.props.history.location.selectedServices,
                        referralCode: this.props.history.location.referralCode,
                        dataIsLoaded: true
                    })
                } else {
                    this.setState({
                        disableFields: true,
                        bookingType: 2,
                        selectedServices: 1,
                        packId: this.props.history.location.packId,
                        dataIsLoaded: true
                    })
                }
            } else {
                this.setState({
                    bookingType: 1,
                    selectedServices: this.props.history.location.id,
                    dataIsLoaded: true
                })
            }
        })



        // get request for car brands.
        try {
            fetch("/getBrands")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    brands: json,
                });
            })
        } catch {}

          this.executeScroll()
        
    }

    // scroll to top on pressing continue
    executeScroll = () => {
        this.myRef.current.scrollIntoView()
    }

    // on chosing the day it set the available time for the team in that day.
    getTeamAvailabilities = (id) => {
        
        axios
        .post('/getTeamAvailabilities', 
        {
            "team_id": id,
        })
        .then((res) => {
            
            this.setState({
                teamAvailabilitiesResponse: res.data,
            })
        })
        .catch((error) => {
            toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
            
            this.setState({
                continueSuccess: false,
            })
        })
    }

    // checks if all fields are set & if there is a team available
    onPressContinue = () => {
        if (this.state.lng === null || this.state.lat === null){
            toast.error('Please enter your location on the map',
             {position: toast.POSITION.TOP_CENTER, toastId: customId})
        } else {
            this.setState({displayError: false,})
            if (this.state.guest) {
                axios
                .post('/getOrderSummary', 
                {
                    "service_id": this.state.selectedServices,
                    "plate_code": this.state.plateSelectedCode + "-" + this.state.plateNumber,
                    "long": this.state.lng,
                    "lat": this.state.lat,
                    "car_model_id": this.state.selectedCarModel,
                    "referral_code": this.state.referralCode
                })
                .then((res) => {
                    try {
                        this.getTeamAvailabilities(res.data.team.id)
                        this.setState({
                            team: res.data.team.id,
                            orderSummary: res.data.items,
                            orderSummaryTotal: res.data.total,
                            continueSuccess: true,
                        })
                    }
                    catch {
                    }
                })
                .then(() =>{
                    if(this.state.continueSuccess){
                        axios
                        .post('/getSMSVerificationCode', 
                        {
                            "phone_number": "+971" + this.state.phoneNumber
                        })
                        .catch((error) => {
                            toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                        })
                    }
                })
                .catch(error => {
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                })
                
            } else if (this.state.bookingType === 1 && !this.state.guest) {
                // Service
                axios
                .post('/getOrderSummary', 
                {
                    "service_id": this.state.selectedServices,
                    "plate_code": this.state.plateSelectedCode + "-" + this.state.plateNumber,
                    "long": this.state.lng,
                    "lat": this.state.lat,
                    "car_model_id": this.state.selectedCarModel,
                    "referral_code": this.state.referralCode
                })
                .then((res) => {
                    try {
                        this.getTeamAvailabilities(res.data.team.id)
                        this.setState({
                            team: res.data.team.id,
                            orderSummary: res.data.items,
                            orderSummaryTotal: res.data.total,
                            continueSuccess: true,
                        })
                    }
                    catch {
                    }
                })
                .catch(error => {
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                })
            } else if (this.state.bookingType === 2) {
                // Pack
                axios
                .post('/getOrderSummary', 
                {
                    "pack_id": this.state.packId,
                    "plate_code": this.state.plateSelectedCode + "-" + this.state.plateNumber,
                    "long": this.state.lng,
                    "lat": this.state.lat,
                    "car_model_id": this.state.selectedCarModel,
                    "referral_code": this.state.referralCode
                })
                .then((res) => {
                    try {
                        this.getTeamAvailabilities(res.data.team.id)
                        this.setState({
                            team: res.data.team.id,
                            orderSummary: res.data.items,
                            orderSummaryTotal: res.data.total,
                            continueSuccess: true,
                        })
                    }
                    catch {
                    }
                })
                .catch(error => {
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                })
            } else {
                axios
                .post('/getOrderSummary', 
                {
                    "service_id": this.state.selectedServices,
                    "plate_code": this.state.plateSelectedCode + "-" + this.state.plateNumber,
                    "long": this.state.lng,
                    "lat": this.state.lat,
                    "car_model_id": this.state.selectedCarModel,
                    "referral_code": this.state.referralCode
                })
                .then((res) => {
                    try {
                        this.getTeamAvailabilities(res.data.team.id)
                        this.setState({
                            team: res.data.team.id,
                            orderSummary: res.data.items,
                            orderSummaryTotal: res.data.total,
                            continueSuccess: true,
                        })
                    }
                    catch {
                    }
                })
                .catch(error => {
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                })
            }
        }
        this.executeScroll()
    }

    onEntringReferralCode = (code) => {
        this.setState({
            referralCode: code.target.value,
        })
    }

    onEntringFirstName = (name) => {
        this.setState({
            firstName: name.target.value,
        })
        
    }

    onEntringLastName = (name) => {
        this.setState({
            lastName: name.target.value,
        })
    }

    onEntringPhoneNumber = (num) => {
        this.setState({
            phoneNumber: num.target.value,
        })
    }

    onBrandChange = (id) => {
        const modelApi = "/getModelByBrandId/" + id
        fetch(modelApi)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    models: json,
                    selectedCarModel: null,
                    selectedCarBrand: id,
                });
        })
        
    }

    onModelChange = (id) => {
        this.setState({
            selectedCarModel: id,
        })
    }

    onChoosingService = (services) => {
        this.setState({
            selectedServices: services,
        })
        
    }

    onChoosingEmirate = (id) => {
        this.setState({
            plateSelectedEmirate: this.state.Emirates[id-1],
            plateSelectedCodes: this.state.allPlateCodes[id-1],
            plateSelectedCode: null
        })
    }

    onChoosingPlateCode = (code) => {
        this.setState({
            plateSelectedCode: code
        })
    }

    onEntringPlateNumber = (num) => {
        this.setState({
            plateNumber: num.target.value
        })
    }

    // it calls setting time available for the day chosen
    onDateChange = (newDate, dateString) => {
        var splits = dateString.split("-");
        this.setState({
            selectedDate: dateString,
        })
        this.setTeamAvailableTimeForDay(dateString)
    }

    setTeamAvailableTimeForDay = (date) => {
        var dayAvailabilities = this.state.teamAvailabilitiesResponse[date]
        var availableHours = []
        dayAvailabilities.map((availability) => {
            if (Array.isArray(availability)) {
                availability.map((av) => {
                    var startingHour = av.start + 3
                    while (startingHour < av.end + 3) {
                        
                        if (startingHour < 12) {
                            availableHours.push(`${startingHour} am`)
                        } else if (startingHour === 12) {
                            availableHours.push("12 pm")
                        } else {
                            availableHours.push(`${startingHour - 12} pm`)
                        }
                        startingHour += 1
                    }
                })
            } else {
                var startingHour = availability.start + 3
                while (startingHour < availability.end + 3) {
                    
                    if (startingHour < 12) {
                        availableHours.push(`${startingHour} am`)
                    } else if (startingHour === 12) {
                        availableHours.push("12 pm")
                    } else {
                        availableHours.push(`${startingHour - 12} pm`)
                    }
                    startingHour += 1
                }
            }
            
        })
        
        this.setState({
            availableBookingTimes: availableHours,
        })
    }

    onSelectingTime = (time) => {
        const regex = /([0-9]+) ((am)|(pm))/
        const match = time.match(regex)
        if (match[2] === "am") {
            this.setState({
                selectedTime: parseInt(match[1]) - 3,
            })
        } else if (match[1] === "12" && match[2] === "pm") {
            this.setState({
                selectedTime: parseInt(match[1]) - 3,
            })
        } else {
            this.setState({
                selectedTime: parseInt(match[1]) + 12 - 3,
            })
        }
    }

    onEntringCommnets = (text) => {
        this.setState({
            commnets: text.target.value,
        })
        
    }

    disabledDate(current) {
        return current > moment().add(29, 'days') || current < moment().subtract(1, 'days')
    }

    setLatLng = (newLat, newLng) => {
        this.setState({
            lat: newLat,
            lng: newLng,
        })
    }

    setVerificationCode = (code) => {
        this.setState({
            verificationCode: code.target.value,
        })
    }

    finish = () => {
        if (this.state.isFinishClickable) {
            this.setState({
                isFinishClickable: false,
            })
            if (this.state.guest) { // guest request for an appointment.
                axios
                .post('/appointments', 
                {
                    
                    "first_name": this.state.firstName,
                    "last_name": this.state.lastName,
                    "phone_number": "+971" + this.state.phoneNumber,
                    "verification_code": this.state.verificationCode,
                    "service_id": this.state.selectedServices,
                    "plate_code": this.state.plateSelectedCode + "-" + this.state.plateNumber,
                    "long": this.state.lng,
                    "lat": this.state.lat,
                    "date": this.state.selectedDate + " " + this.state.selectedTime + ":00:00",
                    "car_model_id": this.state.selectedCarModel,
                    "comments": this.state.commnets,
                    "referral_code": this.state.referralCode,
                })
                .then((answer) => {
                    
                    toast.success(`Your appointment is booked successfully for ${this.state.selectedDate} on ${this.state.selectedTime+3}:00!`, {position: toast.POSITION.TOP_CENTER})
                    this.props.history.push("/")
                })
                .catch(error => {
                    // toast.error('Something went wrong please try again!', {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    this.setState({
                        isFinishClickable: true,
                    })
                })
            } else if (this.state.bookingType === 1 || this.state.bookingType === 3) {//service or offer
                axios
                .post('/appointments', 
                {
                    "service_id": this.state.selectedServices,
                    "plate_code": this.state.plateSelectedCode + "-" + this.state.plateNumber,
                    "long": this.state.lng,
                    "lat": this.state.lat,
                    "date": this.state.selectedDate + " " + this.state.selectedTime + ":00:00",
                    "car_model_id": this.state.selectedCarModel,
                    "comments": this.state.commnets,
                    "referral_code": this.state.referralCode,
                })
                .then((answer) => {
                    // activetab: 3 => History
                    toast.success(`Your appointment is booked successfully for ${this.state.selectedDate} on ${this.state.selectedTime+3}:00!`, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    this.props.history.push({pathname: '/profile', activetab: 3})
                })
                .catch(error => {
                    // toast.error('Something went wrong please try again!', {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    this.setState({
                        isFinishClickable: true,
                    })
                })
            } else {//pack
                axios
                .post('/appointments', 
                {
                    "pack_id": this.state.packId,
                    "plate_code": this.state.plateSelectedCode + "-" + this.state.plateNumber,
                    "long": this.state.lng,
                    "lat": this.state.lat,
                    "date": this.state.selectedDate + " " + this.state.selectedTime + ":00:00",
                    "car_model_id": this.state.selectedCarModel,
                    "comments": this.state.commnets,
                    "referral_code": this.state.referralCode,
                })
                .then((answer) => {                    
                    toast.success(`Your appointment is booked successfully for ${this.state.selectedDate} on ${this.state.selectedTime+3}:00!`, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    this.props.history.push({pathname: '/profile', activetab: 1})
                })
                .catch(error => {
                    // toast.error('Something went wrong please try again!', {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    toast.error(error.response.data.message, {position: toast.POSITION.TOP_CENTER, toastId: customId})
                    this.setState({
                        isFinishClickable: true,
                    })
                })
            }
        }
        
    }


    render() {
        toast.configure();
        const validateMessages = {
            required: '${label} is required!',
            types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
            },
            number: {
            range: '${label} must be between ${min} and ${max}',
            },
        };

        const onRequiredTypeChange = ({ requiredMarkValue }) => {
            setRequiredMarkType(requiredMarkValue);
        };

        const { Option } = Select;

        
        return(
            
            <MDBContainer fluid className="booking-forms-container-special">
                <div className="solve-margin-problem">
                {
                    this.state.continueSuccess ?
                    <MDBRow>
                        <div ref={this.myRef} className="top-div-to-scroll-to"></div>
                        <MDBRow>
                            <p  className="booking-check-out">Check out</p>
                        </MDBRow>
                        <MDBRow>
                            <p className="check-out-title">We’re almost done!</p>
                        </MDBRow>
                        <MDBRow>
                            <p className="booking-add-details">Now Add more details for your request to be fully prepared.</p>
                        </MDBRow>
                        <MDBRow>
                            <Form form={this.form} layout="vertical" validateMessages={validateMessages} onFinish={this.finish}>
                                <MDBRow>
                                    {/* Left Column */}
                                    <MDBCol md="5" className="booking-details-left">
                                        <MDBRow>
                                            <MDBCol>
                                                <p className="order-summary">
                                                    Order Summary
                                                </p>
                                            </MDBCol>
                                        </MDBRow>
                                        {
                                            this.state.orderSummary.map((item) => (
                                                <div key={item}>
                                                    <hr/>
                                                    <MDBRow>
                                                        <MDBCol md="9">
                                                            <p className="check-out-field">
                                                                {item.title}
                                                            </p>
                                                        </MDBCol>
                                                        <MDBCol md="3">
                                                            <p className="check-out-price">
                                                                {item.cost} AED
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    {item.discountedCost == undefined ?
                                                        <></>
                                                        :
                                                        <MDBRow className="check-out-discount-row">
                                                            <MDBCol md="9">
                                                                <p className="check-out-field">
                                                                    Discount
                                                                </p>
                                                            </MDBCol>
                                                            <MDBCol md="3">
                                                                <p className="check-out-price">
                                                                    {Math.round(item.cost - item.discountedCost)} AED
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    }
                                                </div>
                                            ))
                                        }
                                        
                                        
                                        
                                        <hr className="bold-hr"/>
                                        <MDBRow>
                                            <MDBCol md="9">
                                                <p className="check-out-field-total">
                                                    Total
                                                </p>
                                            </MDBCol>
                                            <MDBCol md="3">
                                                <p className="check-out-price-total">
                                                    {this.state.orderSummaryTotal} AED
                                                </p>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>

                                    {/* right Column */}
                                    <MDBCol md="5" className="booking-details-right">
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <DatePicker className="check-out-date-picker" disabledDate={this.disabledDate}  onChange={this.onDateChange}/>
                                            </MDBCol>
                                            <MDBCol md="6" className="check-out-time-picker-col">
                                                <Form.Item
                                                className="booking-form-item"
                                                name={'time'}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: "time is required!"
                                                },  
                                                ]}>
                                                    <Select placeholder="Select time" onChange={this.onSelectingTime} className="check-out-time-picker" showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                        {this.state.availableBookingTimes.map( (item) => (
                                                            <Option value={item} key={item}>{item}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        {
                                            this.state.guest?
                                            <MDBRow>
                                                <MDBCol className="check-out-verification-code-col" md="8">
                                                    <Form.Item
                                                    className="booking-form-item"
                                                    name={'verificationCode'}
                                                    label="Verification Code"
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'verification code is required!',
                                                    },
                                                    {
                                                        pattern: /^(?:\d*)$/,
                                                        message: "Should contain numbers only!",
                                                    },
                                                    {
                                                        pattern: /^[\d]{4}$/,
                                                        message: "Should be 4 numbers!",
                                                    },
                                                    ]}>
                                                        <Input className="check-out-verification-code-input" onChange={this.setVerificationCode} maxLength={4}/>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>
                                            :
                                            null
                                        }
                                        <MDBRow>
                                            <MDBCol className="check-out-comments-col" md="12">
                                                <Form.Item
                                                className="booking-form-item"
                                                name={'comments'}
                                                label="Comments"
                                                
                                                rules={[
                                                {
                                                    required: false,
                                                },
                                                ]}>
                                                    <Input.TextArea className="check-out-comments" onChange={this.onEntringCommnets}/>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="8" className="check-out-btn-col">
                                                
                                                <Form.Item>
                                                    <Button className="check-out-btn" type="primary" htmlType="submit">
                                                        Finish
                                                    </Button>
                                                </Form.Item>
                                                
                                                
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </Form>
                        </MDBRow>
                    </MDBRow>
                    :
                    <MDBRow>
                        <div ref={this.myRef} className="top-div-to-scroll-to"></div>
                        <MDBRow>
                            <p className="booking-title">We’re almost done!</p>
                        </MDBRow>
                        <MDBRow>
                            <p className="booking-add-details">Now Add more details for your request to be fully prepared.</p>
                        </MDBRow>
                        <MDBRow>
                            <Form form={this.form} layout="vertical" validateMessages={validateMessages} onFinish={this.onPressContinue}
                            fields={[
                                {
                                    name: ["first_name"],
                                    value: this.state.firstName
                                },
                                {
                                    name: ["last_name"],
                                    value: this.state.lastName
                                },
                                {
                                    name: ["phone"],
                                    value: this.state.phoneNumber
                                },
                                {
                                    name: ["selected_services"],
                                    value: this.state.selectedServices
                                },
                                {
                                    name: ["brand"],
                                    value: this.state.selectedCarBrand
                                },
                                {
                                    name: ["model"],
                                    value: this.state.selectedCarModel
                                },
                                {
                                    name: ["Emirates"],
                                    value: this.state.plateSelectedEmirate
                                },
                                {
                                    name: ["Code"],
                                    value: this.state.plateSelectedCode
                                },
                                {
                                    name: ["plate_Number"],
                                    value: this.state.plateNumber
                                },
                                {
                                    name: ["Referral_Code"],
                                    value: this.state.referralCode
                                },
                            ]}>
                                <MDBRow>

                                    {/* Left Column */}

                                    <MDBCol md="5" className="booking-details-left">
                                        {this.state.guest?
                                        <>
                                        <MDBRow className="booking-form-row">
                                            <MDBCol md="4" className="booking-first-name">
                                                <Form.Item
                                                name="first_name"
                                                label="First Name"
                                                rules={[
                                                {
                                                    required: true,
                                                },
                                                ]}>
                                                    <Input onChange={this.onEntringFirstName}/>
                                                </Form.Item>
                                            </MDBCol>
                                            <MDBCol md="4">
                                                <Form.Item
                                                name={'last_name'}
                                                label="Last Name"
                                                rules={[
                                                {
                                                    required: true,
                                                },
                                                ]}>
                                                    <Input onChange={this.onEntringLastName}/>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="booking-form-row">
                                            <MDBCol md="8" className="booking-phone-number-col">
                                                <Form.Item
                                                name="phone"
                                                label="Phone Number"
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'phone number is required!',
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Should contain numbers only!",
                                                },
                                                {
                                                    pattern: /^[\d]{9}$/,
                                                    message: "Should be 9 numbers!",
                                                },
                                                ]}>
                                                    <Input maxLength={9} onChange={this.onEntringPhoneNumber} className="booking-phone-number" addonBefore=
                                                    {
                                                        <Select className="booking-nested-phone-select-code" value="+971" disabled
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                            <Option value="+971">+971</Option>
                                                        </Select>
                                                    }
                                                    />
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        </>
                                        :
                                        null
                                        }
                                        <MDBRow className="booking-form-row">
                                            <MDBCol md="8" className="booking-car-brand-col">
                                                <Form.Item
                                                name={'brand'}
                                                label="Choose your car brand"
                                                rules={[
                                                {
                                                    required: true,
                                                    message: "car brand is required!"
                                                },  
                                                ]}>
                                                    <Select onChange={this.onBrandChange} className="booking-car-brand" showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                        {this.state.brands.map( (item) => (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="booking-form-row">
                                            <MDBCol md="8" className="booking-car-model-col">
                                                <Form.Item
                                                name={'model'}
                                                label="Choose your car model"
                                                rules={[
                                                {
                                                    required: true,
                                                    message: "car model is required!"
                                                },  
                                                ]}>
                                                    <Select className="booking-car-model"
                                                    allowClear showSearch
                                                    onChange={this.onModelChange}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    getPopupContainer={triggerNode => triggerNode.parentNode}>                                                
                                                        {this.state.models.map( (item) => (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="booking-form-row">
                                            <MDBCol md="8" className="booking-services-col">
                                                <Form.Item
                                                name={'selected_services'}
                                                label="Service request type"
                                                rules={[{ required: true, message: 'service is required!',}]}>
                                                    <Select 
                                                    disabled={this.state.disableFields}
                                                    onChange={this.onChoosingService} showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                        {this.state.services.map( (item) =>(
                                                            <Option value={item.id} key={item.id}>{item.title}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="booking-form-row">
                                            <MDBCol md="3" className="booking-plate-emirate">
                                                <Form.Item
                                                name={'Emirates'}
                                                label="Emirates"
                                                rules={[{ required: true, message: 'Emirate is required!'}]}>
                                                        <Select showSearch
                                                        onChange={this.onChoosingEmirate}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                            {this.state.plateEmirates.map( (item) =>(
                                                                <Option value={item.id} key={item.id}>{item.title}</Option>
                                                            ))}
                                                        </Select>
                                                </Form.Item>
                                            </MDBCol>
                                            <MDBCol md="2" className="booking-plate-code">
                                                <Form.Item
                                                name={'Code'}
                                                label="Code"
                                                rules={[{ required: true,}]}>
                                                        <Select showSearch 
                                                        onChange={this.onChoosingPlateCode}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                            {this.state.plateSelectedCodes.map( (item) =>(
                                                                <Option value={item.code} key={item.id}>{item.code}</Option>
                                                            ))}
                                                        </Select>
                                                </Form.Item>
                                            </MDBCol>
                                            <MDBCol md="3" className="booking-plate-number">
                                                <Form.Item
                                                name={'plate_Number'}
                                                label="plate Number"
                                                rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Should contain numbers only!",
                                                },
                                                {
                                                    pattern: /^[\d]{0,5}$/,
                                                    message: "Maximum is 5 numbers!",
                                                },
                                                ]}>
                                                        <Input onChange={this.onEntringPlateNumber}/>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>

                                    {/* right Column */}
                                    <MDBCol md="5" className="booking-details-right">
                                        <MDBRow className="booking-form-row">
                                            <MDBCol md="8" className="">
                                                <Form.Item
                                                    name={'Referral_Code'}
                                                    label="Referral Code"
                                                    rules={[
                                                    {
                                                        required: false,
                                                    },
                                                    ]}>
                                                    <Input disabled={this.state.disableFields} onChange={this.onEntringReferralCode}/>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <p className="booking-entre-your-location">
                                                Enter your car location
                                            </p>
                                        </MDBRow>
                                        <MDBRow className="map-row">
                                            <MapComponent setLatLng={this.setLatLng}/>
                                        </MDBRow>
                                        <MDBRow>
                                            <p className={this.state.displayError? 
                                            "booking-error" :
                                            "booking-no-error"}>
                                                There is a missing field please try again!
                                            </p>
                                        </MDBRow>
                                        <MDBRow>
                                            <Form.Item>
                                                <Button className="booking-continue" type="primary" htmlType="submit">
                                                    CONTINUE {'>'}
                                                </Button>
                                            </Form.Item>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </Form>
                        </MDBRow>
                    </MDBRow>
                }
                </div>
            </MDBContainer>
        )
    }
}

export default withRouter(BookingServiceForm);
