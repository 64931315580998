import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect, Link } from "react-router-dom";
import { Layout } from "antd";
import LoadingIndicator from "./HelperComponents/LoadingIndicator";

import Loadable from 'react-loadable';

const AboutUs = Loadable({
    loader: () => import('./Pages/AboutUs/aboutUs'),
    loading: LoadingIndicator
});

const Offers = Loadable({
    loader: () => import('./Pages/Offers/offers'),
    loading: LoadingIndicator
});

const Services = Loadable({
    loader: () => import('./Pages/Services/services'),
    loading: LoadingIndicator
});

const BeOurPartner = Loadable({
    loader: () => import('./Pages/BeOurPartner/beOurPartner'),
    loading: LoadingIndicator
});

const SignInMobile = Loadable({
    loader: () => import('./Pages/SignInMobile/signInMobile'),
    loading: LoadingIndicator
});

const TeamsMainComp = Loadable({
    loader: () => import('./Pages/Teams/teamsMainComp'),
    loading: LoadingIndicator
});

const Profile = Loadable({
    loader: () => import('./Pages/Profile/profile'),
    loading: LoadingIndicator
});

import HomePage from "./Pages/Home/homePage";
// import AboutUs from "./Pages/AboutUs/aboutUs";
// import Offers from "./Pages/Offers/offers";
import Footer_01 from "./Footer/footer"
// import Services from "./Pages/Services/services"
// import BeOurPartner from "./Pages/BeOurPartner/beOurPartner"
import SignUp from "./Pages/SignUp/signUp";
import SignIn from "./Pages/SignIn/signIn";
// import SignInMobile from "./Pages/SignInMobile/signInMobile";
// import TeamsMainComp from "./Pages/Teams/teamsMainComp";
import BookingServiceForm from "./Pages/Booking/bookingServiceForm";
// import Profile from "./Pages/Profile/profile";



import logo from "../assets/logoNavBar.png"
import headerRight from "../assets/rightBtnNavBar.png"
import teamHeaderRight from "../assets/teamsRightHeader.png"
import mobileHeader from "../assets/mobileHeader.png"
import mobileHeaderBtn from "../assets/mobileHeaderBtn.png"
import mobileHeaderBtnBlue from "../assets/mobileHeaderBtnBlue.png"
import historyButton from "../assets/historyButton.png"
import profilePicture from '../assets/profile-picture.jpg'


import 'semantic-ui-css/semantic.min.css';
import "antd/dist/antd.css";
import "./generalCss.css"
import './navBar.css'


const { Content } = Layout;


class App extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()  
        this.state = {
            // state to show up sign up pane
            seen: false,
            // state to show up log in pane
            logInSeen: false,
            // state to indicate if a user has logged in
            loggedIn: false,
            // state to indicate if a team has logged in
            teamIsIn: false,
            //showing verify Sign up pane
            verify: false,
            // service id to be booked child(service) -> parent(App) -> child(BookingService)
            serviceId: 0,
            // side pane state false -> not seen, true -> seen.
            sidePaneClicked: false,
            // indecate loding.
            isLoading: true,
            // admin indicator.
            adminIn: false,
        };
        
    }

    componentDidMount() {
        try {
            fetch("/userInfo")
            .then((res) => res.json())
            .then((json) => {
                if (json.user_type === "Customer") {
                    this.setState({
                        loggedIn: true,
                        teamIsIn: false,
                    }) 
                } else if (json.user_type === "Team") {
                    this.setState({
                        loggedIn: false,
                        teamIsIn: true,
                    }) 
                } else if (json.user_type === "Admin") {
                    this.setState({
                        loggedIn: false,
                        adminIn: true,
                    })
                }
            }) 
        } catch {
            this.setState({
                loggedIn: false,
                teamIsIn: false,
            }) 
        }
        this.setState({isLoading: false})
        
    }

    scrollManual = () => {
        window.scrollTo({
            top:0,
            behavior: "smooth",
        })
    }
    executeScroll = () => {
        this.myRef.current.scrollIntoView()
    }

    // serviceId setter
    serviceIdSetter = (data) => {
        this.setState({serviceId: data})
    }
    // for sign up pane
    togglePop = () => {
        this.setState({
            seen: !this.state.seen
        });
    };
    // for sign in pane
    togglePopSignIn = () => {
        this.setState({
            logInSeen: !this.state.logInSeen
        });
    };
    //side pane action
    toggleSidePane = () => {
        this.setState({
            sidePaneClicked: !this.state.sidePaneClicked
        })
    }
    //close side pane and scroll up
    toggleSidePaneWithScrolling = () => {
        this.setState({
            sidePaneClicked: !this.state.sidePaneClicked
        })
        this.myRef.current.scrollIntoView()
    }
    // a user has logged in
    entranceOfUser = () => {
        this.setState({
            loggedIn :  !this.state.loggedIn
        })
    }
    // a team has logged in
    entranceOfTeam = () => {
        this.setState({
            teamIsIn: !this.state.teamIsIn,
        })
    }
    // an admin has logged in
    entranceOfAdmin = () => {
        this.setState({
            adminIn: !this.state.adminIn
        })
        this.componentDidMount
    }
    signOutRequest = async() => {
        await fetch('/users/sign_out',{ method: 'DELETE' });
        this.setState({
            adminIn: !this.state.adminIn
        })
    }
    render () {
        if (this.state.isLoading)
            return <div></div>
        return (
            <Layout className="layout">
                
                <div className="windows-layout">
                    {
                        this.state.adminIn?
                        <div className="admin-container">
                            <div className="dashboard-btn">
                                <a href="/admin" className="dashboard-link">Dashboard</a>
                            </div>
                            <div className="log-out-btn" onClick={this.signOutRequest}>
                                Log Out
                            </div>
                        </div>
                        :
                        this.state.teamIsIn? 
                        //z-index is 1051 as it is just more than the z-index of the comboboxes
                        <div>
                            <header  className="windows" style={{ position: 'fixed', zIndex: 1051, width: '100%', background: '#fff' }}>
                                <img src={logo} className="header_logo"></img>
                                <img src={teamHeaderRight} className="header-right-team"/>
                            </header>
                            <TeamsMainComp logTeamOut={this.entranceOfTeam}/>
                        </div>
                        
                        :
                        <Router>
                            <img src={mobileHeader} className="mobile-header"/>
                            <div className="mobile-header-solid-color"/>
                            {
                                this.state.sidePaneClicked ?
                                <img src={mobileHeaderBtnBlue} className="mobile-header-btn" onClick={this.toggleSidePane}/>
                                :
                                <img src={mobileHeaderBtn} className="mobile-header-btn" onClick={this.toggleSidePane}/>
                            }
                            
                            
                            {/* SidePane */}
                            <div  className={this.state.sidePaneClicked? "side-pane-content-container-new" : "side-pane-content-container-new-hide"}>
                                <NavLink exact={true} className="side-pane-nav-item" to='/' activeClassName="side-pane-nav-item-active" onClick={this.toggleSidePaneWithScrolling}>
                                    Home
                                </NavLink>
                                <NavLink className="side-pane-nav-item" to='/services' activeClassName="side-pane-nav-item-active" onClick={this.toggleSidePaneWithScrolling}>
                                    Services
                                </NavLink>
                                <NavLink className="side-pane-nav-item" to='/offers' activeClassName="side-pane-nav-item-active" onClick={this.toggleSidePaneWithScrolling}>
                                    Offers
                                </NavLink>
                                <NavLink className="side-pane-nav-item" to='/about-us' activeClassName="side-pane-nav-item-active" onClick={this.toggleSidePaneWithScrolling}>
                                    About Us
                                </NavLink>
                                <NavLink className="side-pane-nav-item" to='/be-our-partner' activeClassName="side-pane-nav-item-active" onClick={this.toggleSidePaneWithScrolling}>
                                    Be Our Partner
                                </NavLink>
                                {
                                    this.state.loggedIn ?
                                    <NavLink className="side-pane-nav-item" to={{pathname: '/profile', activetab: 1}} activeClassName="side-pane-nav-item-active" onClick={this.toggleSidePaneWithScrolling}>
                                        Profile
                                    </NavLink>
                                    :
                                    <NavLink className="side-pane-nav-item" to='/signIn' 
                                    activeClassName="side-pane-nav-item-active" 
                                    onClick={this.toggleSidePaneWithScrolling}>
                                        Log In/Sign Up
                                    </NavLink>
                                }
                            </div>

                            <header  className="windows" style={{ position: 'fixed', zIndex: 1051, width: '100%', background: '#fff' }}>
                                <img src={logo} className="header_logo"></img>
                                
                                <div className="nav-items">
                                    <NavLink exact={true} className="nav-item" to='/' activeClassName="nav-item-active" onClick={this.executeScroll}>Home</NavLink>
                                    <NavLink className="nav-item" to='/services' activeClassName="nav-item-active" onClick={this.executeScroll}>Services</NavLink>
                                    <NavLink className="nav-item" to='/offers' activeClassName="nav-item-active" onClick={this.executeScroll}>Offers</NavLink>
                                    <NavLink className="nav-item" to='/about-us' activeClassName="nav-item-active" onClick={this.executeScroll}>About Us</NavLink>
                                    <NavLink className="nav-item" to='/be-our-partner' activeClassName="nav-item-active" onClick={this.executeScroll}>Be Our Partner</NavLink>
                                    
                                </div>
                        
                                {
                                    this.state.loggedIn && !this.state.adminIn? 
                                    // <HistoryBtnAndProfile/>
                                    <>
                                        <NavLink to={{pathname: '/profile', activetab: 3}}>
                                            <img src={historyButton} className="header_right"/>
                                        </NavLink>
                                        <NavLink to={{pathname: '/profile', activetab: 1}}>
                                            <span className="profile-circle">
                                                <img src={profilePicture} className="profile-picture-circle"/>
                                            </span>
                                        </NavLink>
                                    </>
                                    :
                                    <img src={headerRight} onClick={this.togglePopSignIn} className="header_right"/>
                                }

                                
                            </header>

                        
                            {this.state.seen ? <SignUp toggle={this.togglePop} toggleSignIn={this.togglePopSignIn} userEntered={this.entranceOfUser} className="sign-up-pop-up"/> : null}
                            {this.state.logInSeen ? <SignIn toggle={this.togglePop} toggleSignIn={this.togglePopSignIn} userEntered={this.entranceOfUser} teamEntered={this.entranceOfTeam} adminEntered={this.entranceOfAdmin} className="sign-up-pop-up"/> : null}
                            
                            <Content style={{ padding: "0 0px" }}>
                                <div className="top-div-to-scroll-to" ref={this.myRef}></div>
                                <div className="site-layout-content" >
                                    <Switch>
                                        <Route exact path="/"  render={
                                            props => <HomePage {...props} logOutUser={this.entranceOfUser}/>
                                        }/>
                                        {// if team enteredx     disable all routes
                                            this.state.teamIsIn || this.state.adminIn?
                                            null
                                            :
                                            <Route exactly path="/services" component={Services}/>
                                        }
                                        {// if team entered disable all routes
                                            this.state.teamIsIn || this.state.adminIn?
                                            null
                                            :
                                            <Route exact path="/signIn"  render={
                                                props => <SignInMobile {...props} 
                                                userentered={this.entranceOfUser}
                                                teamentered={this.entranceOfTeam}
                                                adminentered={this.entranceOfAdmin}/>
                                            }/>
                                        }
                                        {/* <Route exactly path="/signIn" component={SignInMobile}/> */}
                                        {// if team entered disable all routes
                                            this.state.teamIsIn || this.state.adminIn?
                                            null
                                            :
                                            <Route exactly path="/booking-service" component={BookingServiceForm}/>
                                        }
                                        {// if team entered disable all routes
                                            this.state.teamIsIn || this.state.adminIn?
                                            null
                                            :
                                            <Route exactly path="/be-our-partner" component={BeOurPartner}/>
                                        }
                                        {// if team entered disable all routes
                                            this.state.teamIsIn || this.state.adminIn?
                                            null
                                            :
                                            <Route exactly path="/about-us" component={AboutUs}/>
                                        }
                                        {// if team entered disable all routes
                                            this.state.teamIsIn || this.state.adminIn?
                                            null
                                            :
                                            <Route exactly path="/offers" component={Offers}/>
                                        }
                                        {
                                            this.state.loggedIn?
                                            <Route exactly path="/profile" component={Profile}/> 
                                            :
                                            null
                                        }
                                        <Redirect to="/"></Redirect>
                                        
                                    </Switch>
                                    
                                </div>
                            </Content>
                            <Footer_01/>
                        </Router>
                    }
                
                </div>
                {/* <Footer_01/> */}
            </Layout>
        )
    }
}

export default App;