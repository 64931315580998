import React, {useState} from "react";
import { withRouter } from 'react-router-dom'

import bookingSteps from "../../../assets/bookingSteps.png"
import bookingStepsMobile from "../../../assets/bookingStepsMobile"

import OffersPacks from "./offers";
import FloattingButton from "./floattingButton";
import ImageGallery from "./imageGallery";



import './homePage.css'
import App from "../../App";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        var logOut = this.props.history.location.logOutData
        if (logOut) {
            this.props.logOutUser()
        }
        this.state = {
            
        };
    }

    
    render() {
        return (
            <div className="container">
                
                <div className="windows-display">
                    
                    <div className="packs-windows">
                        <FloattingButton/>
                        <ImageGallery/>
                        <p className="our-offers">Our Offers</p>
                        <p className="get-best-price">Get best pricing offer for our services</p>
                        <OffersPacks customerLoggedIn={this.props.customerLoggedIn}/>
                    </div>
                    <img src={bookingSteps} className="steps-windows"></img>
                </div>
                <div className="mobile">
                    <img className="home-page-steps" src={bookingStepsMobile} ></img>
                </div>
            </div>
        );
    }
}

    
export default withRouter(HomePage);