import React, {useState} from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { Form, Input, Select, Button } from 'antd';
import { withRouter } from 'react-router-dom'
import axios from "axios";

import VerifySignUp from "./verifySignUp";

// import lineImage from '../../../assets/signUpWithLine.png'
// import facebook from '../../../assets/signUpWithFacebook'
// import google from '../../../assets/signUpWithGoogle'
import sinUpImage from '../../../assets/signUpImg.png'

import './signUp.css'


class SignUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            mail: "",
            phone: "",
            password: "",
            confirmPassword: "",
            displayError: false,
            buttonBushed: false,
            code: "",
            emailUsed: false,
        }
    }

    handleClick = () => {
        this.props.toggle();
        this.props.toggleSignIn();
    };

    handleSkip = () => {
        this.props.toggle();
    };

    onEntringFirstName = (input) => {
        this.setState({
            firstName: input.target.value,
        })
    }

    onEntringLastName = (input) => {
        this.setState({
            lastName: input.target.value,
        })
    }

    onEntringPhoneNumber = (input) => {
        this.setState({
            phone: input.target.value,
        })
    }

    onEntringMail = (input) => {
        this.setState({
            mail: input.target.value,
        })
    }

    onEntringPassword = (input) => {
        this.setState({
            password: input.target.value,
        })
    }

    onEntringConfirmPassword = (input) => {
        this.setState({
            confirmPassword: input.target.value,
        })
    }

    signUpAction = () => {
        axios
        .post('/emailAvailability', 
        {
            "email": this.state.mail
        })
        .then(response => {
            if (response.data) {
                this.setState({
                    displayError: false,
                    buttonBushed: true,
                    emailUsed: false,
                })
                axios
			.post('/getSMSVerificationCode', 
            {
                "phone_number": "+971" + this.state.phone
            })
                
            } else {
                this.setState({
                    emailUsed: true,
                })
            }
        })
        .catch(error => {
            this.setState({
                displayError: false,
            })
        })
    }

    onEntringVerificationCode = (newCode) =>{
        this.setState({
            code: newCode.target.value,
        })
    }

    

    verificationAction = () => {
        if (this.state.code.length != 4){
            this.setState({
                displayError: true,
            })
        }else{
            this.setState({
                displayError: false,
            })
            this.callSignUp();

        }
    }

    callSignUp = () => {
		axios
			.post('/users', 
            {
                "verification_code": this.state.code,
                "user": {
                    "email": this.state.mail,
                    "password": this.state.password,
                    "first_name": this.state.firstName,
                    "last_name": this.state.lastName,
                    "phone_number": "+971" + this.state.phone 
                }
            })
			.then(response => {
				
            if (response.status === 200){
                this.props.toggle();
                this.props.userEntered();
                this.props.history.push(
                    {
                        pathname: '/',
                    }
                );
            } else {
                this.setState({
                    displayError: true,
                }) 
            }
			})
			.catch(error => {
				
                this.setState({
                    displayError: true,
                })
			})
	}

    render() {
        const { Option } = Select;

        const RegistrationForm = () => {
            const [form] = Form.useForm();
          
            const onFinish = (values) => {
                
            };
        }
        
        return (
            <div>
                <div className="sign-up-black" onClick={this.handleSkip}/>
                <div className="sign-up-card">
                    <p className="sign-up-back" onClick={this.handleClick}><span>&#60;</span> Back</p>
                    <img src={sinUpImage} className="sign-up-image"></img>
                    {this.state.buttonBushed? 
                        <div className="sign-up-righty">
                            <MDBContainer fluid className="verify-sign-up-container">
                                <MDBRow>
                                    <MDBCol className="sign-up-verify-title-col">
                                        <p className="sign-up-verify-title">
                                            One step closer.
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="sign-up-verify-info-col">
                                        <p className="sign-up-verify-info">
                                            We’ve sent a verification code to your 
                                            phone number, please apply code to continue process.
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="sign-up-verify-digits-info-col">
                                        <p className="sign-up-vrify-digits-info">
                                            Enter 4 digit code
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <Form
                                        className="verification-form"
                                        form={this.form}
                                        name="register"
                                        onFinish={this.verificationAction}
                                        scrollToFirstError>
                                            <MDBRow>
                                                <MDBCol md="8" className="verification-code-col">
                                                    <Form.Item
                                                    name={'verification code'}
                                                    label=""
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'verification code is required!',
                                                    },
                                                    {
                                                        pattern: /^(?:\d*)$/,
                                                        message: "Should contain numbers only!",
                                                    },
                                                    {
                                                        pattern: /^[\d]{4}$/,
                                                        message: "Should be 4 numbers!",
                                                    },
                                                    ]}>
                                                        <Input className="verification-code-input" onChange={this.onEntringVerificationCode} maxLength={4}/>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>
                                        
                                            <MDBRow>
                                                <MDBCol className="verification-resend-code-col">
                                                    <p className="verification-resend-code">
                                                        Resend code
                                                    </p>
                                                </MDBCol>
                                            </MDBRow>
                                            {/* <MDBRow>
                                                <MDBCol className="verification-send-via-mail-col">
                                                    <p className="verification-send-via-mail">
                                                        Send code to my email instead
                                                    </p>
                                                </MDBCol>
                                            </MDBRow> */}
                                            <MDBRow>
                                                <MDBCol className="verification-button-col">
                                                    {/* <div className="verification-button" onClick={this.verificationAction}>
                                                        Verify
                                                    </div> */}
                                                    <Form.Item>
                                                        <Button className="verification-button" type="primary" htmlType="submit">
                                                            Verify
                                                        </Button>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol className="verification-error-col">
                                                    {this.state.displayError?
                                                        <p className="verification-error">
                                                            SomeThing went wrong, Please try again!
                                                        </p>
                                                        :
                                                        null
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                
                                            </MDBRow>
                                        </Form>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </div>
                        : 
                        <div className="sign-up-righty">
                            <p className="sign-up-skip" onClick={this.handleSkip}>Skip <span>&#62;</span></p>
                            <MDBContainer fluid className="sign-up-form-container">
                                <MDBRow>
                                    <MDBCol className="start-for-free-col">
                                        <p className="start-for-free">START FOR FREE</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="sign-up-to-SeyanaCar-col">
                                        <p className="sign-up-to-SeyanaCar">Sign up to SeyanaCar</p>
                                    </MDBCol>
                                </MDBRow>
                                
                                <MDBRow className="sign-up-form-row">
                                    <MDBCol>
                                        <Form
                                        className="sign-up-form"
                                        form={this.form}
                                        name="register"
                                        onFinish={this.signUpAction}
                                        scrollToFirstError>

                                            <MDBRow className="sign-up-names-row">
                                                <MDBCol md="4" className="sign-up-first-name-col">
                                                    <Form.Item
                                                    name={'first name'}
                                                    label="First Name"
                                                    rules={[
                                                    {
                                                        required: true,
                                                    },
                                                    ]}>
                                                        <Input onChange={this.onEntringFirstName}/>
                                                    </Form.Item>
                                                </MDBCol>
                                                <MDBCol md="4" className="sign-up-last-name-col">
                                                    <Form.Item
                                                    name={'last name'}
                                                    label="Last Name"
                                                    rules={[
                                                    {
                                                        required: true,
                                                    },
                                                    ]}>
                                                        <Input onChange={this.onEntringLastName}/>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow className="sign-up-phone-row">
                                                <MDBCol md="9">
                                                    <Form.Item
                                                    name="phone"
                                                    label="Phone Number"
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'phone number is required!',
                                                    },
                                                    {
                                                        pattern: /^(?:\d*)$/,
                                                        message: "Should contain numbers only!",
                                                    },
                                                    {
                                                        pattern: /^[\d]{9}$/,
                                                        message: "Should be 9 numbers!",
                                                    },
                                                    ]}>
                                                        <Input maxLength={9} onChange={this.onEntringPhoneNumber} className="booking-phone-number" addonBefore=
                                                        {
                                                            <Select className="booking-nested-phone-select-code" value="+971" disabled
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                                <Option value="+971">+971</Option>
                                                            </Select>
                                                        }
                                                        />
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow className="sign-up-mail-row">
                                                <MDBCol md="9">
                                                    <Form.Item
                                                    name="email"
                                                    label="E-mail"
                                                    rules={[
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail!',
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Please input your E-mail!',
                                                    },
                                                    ]}>
                                                        <Input onChange={this.onEntringMail}/>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow className="sign-up-password-row">
                                                <MDBCol md="9">
                                                    <Form.Item
                                                    name="password"
                                                    label="Password"
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                    {
                                                        pattern: /^[\S\s]{6,}$/,
                                                        message: "Should be more than 6 characters!",
                                                    },
                                                    ]}
                                                    hasFeedback>
                                                        <Input.Password onChange={this.onEntringPassword}/>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow className="sign-up-confirm-password-row">
                                                <MDBCol md="9" className="sign-up-confirm-password-col">
                                                    <Form.Item
                                                    name="confirm"
                                                    label="Confirm Password"
                                                    dependencies={['password']}
                                                    hasFeedback
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                        },
                                                    }),
                                                    ]}>
                                                        <Input.Password onChange={this.onEntringConfirmPassword}/>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="sign-up-button-row">
                                                <MDBCol md="12" className="sign-up-button-col">
                                                    {/* <div className="sign-up-button" onClick={this.signUpAction}>
                                                        Create Account
                                                    </div> */}
                                                    <Form.Item>
                                                        <Button className="sign-up-button" type="primary" htmlType="submit">
                                                            Create Account
                                                        </Button>
                                                    </Form.Item>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol className="sign-up-error-col">
                                                    {this.state.displayError?
                                                        <p className="sign-up-error">
                                                            Something went wrong, Please try again!
                                                        </p>
                                                        :
                                                        null
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBCol className="sign-up-error-col">
                                                    {this.state.emailUsed?
                                                        <p className="sign-up-error">
                                                            Email already used, please try another one or log in!
                                                        </p>
                                                        :
                                                        null
                                                    }
                                                </MDBCol>
                                        </Form>
                                    </MDBCol>
                                </MDBRow>
                                {/* <MDBRow>
                                    <MDBCol md="12">
                                        <img src={lineImage} className="sign-up-line"/>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="sign-up-options">
                                    <MDBCol md="5">
                                        <img src={facebook} className="sign-up-facebook"/>
                                    </MDBCol>
                                    <MDBCol md="5">
                                        <img src={google} className="sign-up-google"/>
                                    </MDBCol>
                                </MDBRow> */}
                                
                                
                                
                                {/* <p className="sign-up-note">By signing in you agree to our</p>
                                <p className="sign-up-privacy-policy"> Privacy Policy</p>
                                <p className="sign-up-and-note">and</p>
                                <p className="sign-up-terms-of-service"> Terms of Service</p> */}
                            </MDBContainer>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(SignUp);