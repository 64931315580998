import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { Form, Input, Select, Button } from 'antd';
import { withRouter } from 'react-router-dom'
import axios from "axios";

// import lineImage from '../../../assets/logInWithLine.png'
// import facebook from '../../../assets/signUpWithFacebook'
// import google from '../../../assets/signUpWithGoogle'


import sinUpImage from '../../../assets/signUpImg.png'

import './signIn.css'

class SignIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mail: "",
            Password: "",
            displayError: false,
            responseMessage: "",
            wringPassOrEmail: false,
        };
    }

    handleSkip = () => {
        this.props.toggleSignIn();
    };

    handleSignUpClick = () => {
        this.props.toggle();
        this.props.toggleSignIn();
    }

    onEntringMail = (input) => {
        this.setState({
            mail: input.target.value,
        })
    }

    onEntringPassword = (input) => {
        this.setState({
            Password: input.target.value,
        })
    }

    signInAction = () => {
        if (this.state.mail.trim() == ""
        || this.state.Password.trim() == "") {
            this.setState({
                displayError: true,
            })
        } else {
            this.setState({
                displayError: false,
            })
            
            this.submitHandler();
        }
    }

    submitHandler = () => {
		axios
        .post('/users/sign_in', 
        {
            "user": {
                "email": this.state.mail,
                "password": this.state.Password,
            }
        })
        .then(response => {
        if (response.status === 200){
            fetch("/userInfo")
            .then((res) => res.json())
            .then((json) => {
                
                if (json.user_type === "Customer") {
                    this.props.userEntered();
                    this.props.history.push(
                        {
                            pathname: '/',
                        }
                    );
                    this.props.toggleSignIn();
                } else if (json.user_type === "Team") {
                    this.props.teamEntered();
                    this.props.history.push(
                        {
                            pathname: '/',
                        }
                    );
                    this.props.toggleSignIn();
                } else if (json.user_type === "Admin") {
                    this.props.adminEntered();
                    this.props.history.push(
                        {
                            pathname: '/',
                        }
                    );
                    this.props.toggleSignIn();
                } else {
                    this.setState({
                        displayError: true,
                    })
                }  
            })
        } else {
            this.setState({
                displayError: true,
            })
            
        }
        })
        .catch(error => {
            
            this.setState({
                displayError: true,
            })
        })
	}

    render() {
        const { Option } = Select;


        const RegistrationForm = () => {
            const [form] = Form.useForm();
        
            const onFinish = (values) => {
                
            };
        }

        return (
            <div className="display-on-mobile">
                <div className="log-in-black" onClick={this.handleSkip}/>
                <div className="log-in-card">
                    {/* <p className="log-in-back" onClick={this.handleClick}><span>&#60;</span> Back</p> */}
                    <img src={sinUpImage} className="log-in-image"></img>
                    <div className="log-in-righty">
                        <p className="log-in-skip" onClick={this.handleSkip}>Skip <span>&#62;</span></p>
                        <MDBContainer fluid className="sign-in-form-container">
                            <MDBRow>
                                <MDBCol className="we-are-here-col">
                                    <p className="we-are-here">WE SERVICE YOUR CAR WHERE EVER YOU ARE!</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" className="sign-up-Already-a-member-col">
                                    <p className="sign-up-Already-a-member">Don't have an account?</p>
                                </MDBCol>
                                <MDBCol md="3" className="sign-up-log-in-col">
                                    <p className="sign-up-log-in" onClick={this.handleSignUpClick}>Sign Up</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className="sign-in-to-SeyanaCar-col">
                                    <p className="sign-in-to-SeyanaCar">Log In</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="sign-in-form-row">
                                <MDBCol>
                                    <Form
                                    className="sign-in-form"
                                    form={this.form}
                                    name="register"
                                    onFinish={this.signInAction}
                                    scrollToFirstError>
                                        <MDBRow>
                                            <MDBCol md="9" className="sign-in-email-col">
                                                <Form.Item
                                                name="email"
                                                label="E-mail"
                                                rules={[
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please input your E-mail!',
                                                },
                                                ]}>
                                                    <Input onChange={this.onEntringMail}/>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow>
                                            <MDBCol md="9" className="sign-in-password-col">
                                                <Form.Item
                                                name="password"
                                                label="Password"
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                                ]}
                                                hasFeedback>
                                                    <Input.Password onChange={this.onEntringPassword}/>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" className="sign-in-button-col">
                                                <Form.Item>
                                                    <Button className="sign-in-button" type="primary" htmlType="submit">
                                                        Log In
                                                    </Button>
                                                </Form.Item>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol className="sign-in-error-col">
                                                {this.state.displayError?
                                                    <p className="sign-in-error">
                                                        Email or password or both are not correct, try again!
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </Form>
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBRow>
                                <MDBCol md="12">
                                    <img src={lineImage} className="sign-in-line"/>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="sign-in-options">
                                <MDBCol md="5">
                                    <img src={facebook} className="sign-in-facebook"/>
                                </MDBCol>
                                <MDBCol md="5">
                                    <img src={google} className="sign-in-google"/>
                                </MDBCol>
                            </MDBRow> */}
                        </MDBContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SignIn);