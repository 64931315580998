import React from "react";
import { Button, Carousel } from 'antd';
import { withRouter } from 'react-router-dom'

import './imageGallery.css'


class ImageGallery extends React.Component{
    // Constructor 
    constructor(props) {
        super(props);
        
        this.state = {
            items: [],
            DataisLoaded: false
        };
    }

    componentDidMount() {
        // fetch offers gallery.
        try {
            fetch("/getOffers")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                });
            })
        } catch {}
    }

    handleClick = () => {
        this.props.history.push("/offers"); 
    }

    render() {
        const { DataisLoaded, items } = this.state;
        if (!DataisLoaded) return <div>
            <h1> Pleses wait some time.... </h1> </div> ;
        return (
            <div>
                <div className="carousal-for-desktop">
                    <Carousel autoplay>
                        {items.map((item) => (
                            <div className="offers-holder" key = { item.id }>
                                {/* <Button type="primary" className="offers-button" onClick={this.handleClick}>
                                    View Offers
                                </Button> */}
                                <img className="gallery-image" src={item.imageurl} onClick={this.handleClick}/>
                                
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className="carousal-for-mobile-holder">
                    <Carousel autoplay className="carousal-for-mobile">
                        {items.map((item) => (
                            <div className="offers-holder" key = { item.id }>
                                {/* <Button type="primary" className="offers-button" onClick={this.handleClick}>
                                    View Offers
                                </Button> */}
                                <img className="gallery-image" src={item.mobile_imageurl} onClick={this.handleClick}/>
                                
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        );
    }
}

export default withRouter(ImageGallery);