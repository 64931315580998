import React, {useState} from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { Form, Input } from 'antd';


import sinUpImage from '../../../assets/signUpImg.png'

import './verifySignUp.css'

class VerifySignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
            displayError: false,
        }
    }

    


    // newSignUpRequest = () => {
	// 	
	// 	axios
	// 		.post('/users', 
    //         {
    //             "verification_code": this.state.code,
    //             "user": {
    //                 "first_name": "kiki",
    //                 "last_name": "kiki",
    //                 "phone_number": "123456789",
    //                 "email": "kiks@kiks.com",
    //                 "password": "123456"
    //             }
    //         })
	// 		.then(response => {
	// 			
    //             
    //         if (result.status === 200){
                
    //         } else {
    //             this.setState({
    //                 displayError: true,
    //             })
    //         }
	// 		})
	// 		.catch(error => {
	// 			
    //             this.setState({
    //                 displayError: true,
    //             })
	// 		})
	// }

    // async signInRequest() {
    //     try {
    //         let result = await fetch('/users',{
    //             method: 'post',
    //             // mode: 'no-cors',
    //             // headers: {
    //             //     'Accept': '*/*',
    //             //     'Content-type': 'application/json',
    //             // },
    //             body: 
    //             JSON.stringify(
    //                 {
    //                     "verification_code": this.state.code,
    //                     "user": {
    //                         "email": "kiks@kiks.com",
    //                         "password": "123456",
    //                         "first_name": "kiki",
    //                         "last_name": "kiki",
    //                         "phone_number": "123456789"
                            
    //                     }
                        
    //                 }
    //             )
    //         });
    //         
    //         if (result.status === 200){
    //             this.setState({
    //                 displayError: false,
    //             })
    //         } else {
                
    //             this.setState({
    //                 displayError: true,
    //             })
    //         }
    //     } catch(e) {
    //         
    //         this.setState({
    //             displayError: true,
    //         })
    //     }
    // }

    

    // async signUpRequest() {
    //     try {
    //         let result = await fetch('/users',{
    //             method: 'POST',
    //             mode: 'no-cors',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-type': 'application/json',
    //             },
    //             body: JSON.stringify(
    //                 {
    //                     "verification_code": this.state.code,
    //                     "user": {
    //                         "first_name": "kiki",
    //                         "last_name": "kiki",
    //                         "phone_number": "123456789",
    //                         "email": "kiks@kiks.com",
    //                         "password": "123456"
    //                     }
    //                 }
    //             )
    //         });
    //         
    //     } catch(e) {
    //         
    //     }
    // }

    
    render () {
        const RegistrationForm = () => {
            const [form] = Form.useForm();
          
            const onFinish = (values) => {
                
            };
        }

        return(
            <></>
        )
    }
}

export default VerifySignUp;