import { Card } from "antd";
import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import ReactHtmlParser from 'react-html-parser'; 

import bronzeTag from '../../../assets/bronzeTag.png'
import silverTag from '../../../assets/silverTag.png'
import goldenTag from '../../../assets/goldenTag.png'
import platinumTag from '../../../assets/platinumTag.png'
import bronzeFooter from '../../../assets/bronzeFooter.png'
import silverFooter from '../../../assets/silverFooter.png'
import goldenFooter from '../../../assets/goldenFooter.png'
import platinumFooter from '../../../assets/platinumFooter.png'
import cardBookNowBronze from '../../../assets/cardBookNowBronze.png'
import cardBookNowSilver from '../../../assets/cardBookNowSilver.png'
import cardBookNowGolden from '../../../assets/cardBookNowGolden.png'
import cardBookNowPlatinum from '../../../assets/cardBookNowPlatinum.png'
import offerCircle from '../../../assets/offerCircle.png'

import './offers.css'



function replaceJSX(str, find, replace) {
    let parts = str.split(find);
    let result = []
    for(let i = 0; i < parts.length; i++) {
        result.push(parts[i]);
        result.push(" " + replace + " ");
    }
    return result;
}

let points = new Array();

function setContent(items) {
    let content = []
    items.forEach(item => {
        content.push(item.content.substring(4))
    });
    content.forEach(line =>{
        points.push(line.split("|t|"))
    })
}

function services(number) {
    if (number == 1 ) {
        return <p className="number-of-services" >( {number} service )</p>
    } else {
        return <p className="number-of-services" >( {number} services )</p>
    }
}

const customId = "custom-id-yes";

class OffersPacks extends React.Component {
   
    // Constructor 
    constructor(props) {
        super(props);
        
        this.state = {
            items: [],
            DataisLoaded: false,
            customerLoggedIn: this.props.customerLoggedIn
        };
    }

    componentDidMount() {
        try {
            fetch("/getPacks")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                });
            })
        } catch {}
    }


    bookNowClicked = (packId) => {
        fetch("/userInfo")
        .then((res) => {
            if (res.status === 200) {
                axios
                .post('/buyPack', 
                {
                    "pack_id": packId
                })
                .then(response => {
                    toast.success('You reserved the pack successfully, Go to the wallet to redeem it!', {position: toast.POSITION.TOP_CENTER, autoClose: 10000, toastId: customId})
                })
                .catch(error => {
                    toast.error('Something went wrong please try again!', {position: toast.POSITION.TOP_CENTER, toastId: customId})
                })
            } else {
                toast.warn('You have to sign in first!', {position: toast.POSITION.TOP_CENTER, toastId: customId})
            }
        }) 
    }

    render() {
        toast.configure()
        const { DataisLoaded, items } = this.state;
        setContent(items)
        if (!DataisLoaded) return <div>
            <h1> Pleses wait some time.... </h1> </div> ;
   
        return (
            // to get packs you have to sign in first.
            <div className = "offers">
                {
                    items.map((item) => (
                    <Card key = { item.id } className="offers-cards" style={{ borderColor:item.color }}>
                        
                        <p className="offer-tag-discount">{item.discount}%</p>
                        <img src={ item.title === "BRONZE"? bronzeTag :
                                item.title === "SILVER"? silverTag :
                                item.title === "GOLDEN"? goldenTag : platinumTag } className="offer-tag">
                        </img>

                        <div className="card-head">
                            <p className="offer-name" style={{color: item.color}}>{item.title}</p>
                            <p className="pack-string">PACK</p>
                            {services(item.services)}
                            <hr/>
                        </div>

                        <br/>
                        
                        <p className="offer-details"> { ReactHtmlParser (item.content
                            .replace(new RegExp('\\|n\\|', 'g'), '\n\n')
                            .replace(new RegExp('\\|t\\|', 'g'), "✅")) }
                        </p>
                        <br/>
                        
                        <p className="offer-price">{ item.price } </p>
                        <p className="offer-currency">AED</p>

                        <img src={ item.title === "BRONZE"? bronzeFooter :
                                item.title === "SILVER"? silverFooter :
                                item.title === "GOLDEN"? goldenFooter : platinumFooter } className="offer-footer">
                        </img>

                        <img src={ item.title === "BRONZE"? cardBookNowBronze :
                                item.title === "SILVER"? cardBookNowSilver :
                                item.title === "GOLDEN"? cardBookNowGolden : cardBookNowPlatinum }
                                className="offer-book-now"
                                onClick={() => this.bookNowClicked(item.id)}>
                        </img>

                        <img src={offerCircle} className="offer-circle"></img>

                    </Card>
                    ))
                }
            </div>
        );
    }
}
   
export default OffersPacks;