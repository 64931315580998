import React from "react";

import callButton from "../../../assets/floattingButton.png"
import callButtonMobile from "../../../assets/iconCall.png"

import './homePage.css'

const FloattingButton = () => {
    return(
        <>
            <img src={callButton} className="floattingBtn"/>
            <a href={`tel:+971800739262`}><img src={callButtonMobile} className="floattingBtnMobile"/></a>
        </>
    )
}

export default FloattingButton;