import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import "@fontsource/metropolis";
import whatsapp from "../../assets/whatsapp.svg"
import youtupe from "../../assets/youtupe.svg"
import facebook from "../../assets/facebook.svg"
import instagram from "../../assets/instagram.svg"
import logo from "../../assets/brandLogo.svg"
import codoo99 from "../../assets/codoo99.png"
import './footer.css'


class Footer extends React.Component {

  constructor(props) {
    super(props);
  }
  // const history = useHistory();

  redirectToBooking = (serviceId) => {
    this.props.history.push({
      pathname: '/booking-service',
      id: serviceId,
      bookingType: 1,
    });
  }

  redirectToBeOurPartner = () => {
    this.props.history.push({
      pathname: '/be-our-partner',
    });
  }

  redirectToAboutUs = () => {
    this.props.history.push({
      pathname: '/about-us',
    });
  }

  render () {
  return (
    <MDBFooter color="black" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow className="footer_margin">
          <MDBCol md="3">
            <h5 className="whiteWords">CONTACT US</h5>
              
              <li className="list-unstyled">E-mail : </li>
              <li className="list-unstyled">info@seyanacar.com</li>
              <li className="list-unstyled">Mobile: 800 739 262</li>
              
          </MDBCol>
          <MDBCol md="3">
            <h5 className="whiteWords">SERVICES</h5>
            
            <li className="list-unstyled">
              <a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(1)
              }
            }>Oil And Filter</a></li>

            <li className="list-unstyled"><a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(2)
              }
            }>Fuel System Cleaning</a></li>
            <li className="list-unstyled"><a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(3)
              }
            }>AC Service</a></li>
            <li className="list-unstyled"><a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(4)
              }
            }>Pre-owned Car Checkup</a></li>
            <li className="list-unstyled"><a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(5)
              }
            }>Tyres service</a></li>
            <li className="list-unstyled"><a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(6)
              }
            }>Battery Replacement</a></li>
            <li className="list-unstyled"><a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(7)
              }
            }>Brakes Service</a></li>
            <li className="list-unstyled"><a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBooking(8)
              }
            }>Car Detailing</a></li>
          
          </MDBCol>

          <MDBCol md="3">
            <h5 className="whiteWords">ABOUT</h5>

            <li className="list-unstyled">
              <a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToAboutUs()
              }
            }>Vision</a>
            </li>
            <li className="list-unstyled">
              <a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBeOurPartner()
              }
            }>Partners</a>
            </li>
            <li className="list-unstyled">
              <a className="list-unstyled">Customer feedback</a>
            </li>
            <li className="list-unstyled">
              <a className="list-unstyled">FAQ’s</a>
            </li>
            <li className="list-unstyled">
              <a className="list-unstyled">Terms And Conditions</a>
            </li>

          </MDBCol>
          <MDBCol md="3">
            <h5 className="whiteWords">Help</h5>

            <li className="list-unstyled">
              <a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBeOurPartner()
              }
            }>Support</a>
            </li>
            <li className="list-unstyled">
              <a className="list-unstyled" onClick={
              (e)=> {
                e.preventDefault();
                this.redirectToBeOurPartner()
              }
            }>Contact</a>
            </li>

          </MDBCol>
          <MDBCol md="6">
            <h5 className="whiteWords">SOCIAL MEDIA</h5>
            <a href="https://wa.me/+971800739262"><img src={whatsapp} className="footer_icons"></img></a>
            <a href="https://www.facebook.com/SeyanaCarcom-105045978577004/"><img src={facebook} className="footer_icons"/></a>
            <a href="https://instagram.com/seyanacar?igshid=YmMyMTA2M2Y="><img src={instagram} className="footer_icons"/></a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <hr className="horLine"></hr>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid className="whiteWords" >
          <MDBRow>
          <MDBCol lg="3">
              <img src={logo} className="footer_logo"></img>
            </MDBCol>
            <MDBCol lg="6" className="copyrights">
              &copy; {new Date().getFullYear()} Copyright: SeyanaCar L.L.C. All rights reserved.
            </MDBCol>
            {/* <MDBCol lg="3">
              <select id="standard-select" className="footer_select">
                <option value="1" defaultValue>English</option>
                <option value="2">Arabic</option>
              </select>
            </MDBCol> */}
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <a href = "mailto: codoo99softwaredev@gmail.com" className="codoo99-footer">Powered by Codoo99</a>
            </MDBCol>       
          </MDBRow>
          
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}
}

export default withRouter(Footer);