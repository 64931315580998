import React, { useState } from "react";
import { Button } from 'antd';

import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";

import './bookingService.css'
import "@reach/combobox/styles.css";

import markerLogo from '../../../assets/smallLogo.png'

const mapContainerStyle = {
    height: "50vh",
    width: "auto",
};

const center = {
    lat: 24.4539,
    lng: 54.3773
};

const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    mapTypeControl: true,
};

const libraries = ["places"];


export default function MapComponent({ setLatLng }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAP_API,
        libraries,
    });

    const [markers, setMarkers] = React.useState([]);

    const onMapClick = React.useCallback((e) => {
        setMarkers((current) => [
          
          {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
            time: new Date(),
          },
        ]);
        setLatLng(e.latLng.lat(), e.latLng.lng())
    }, []);

    const setNewMarker = React.useCallback(({lng, lat}) => {
        setMarkers((current) => [
        {
            lat: lat,
            lng: lng,
            time: new Date(),
        },
        ]);
        setLatLng(lat, lng)
    })

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = React.useCallback( ({lng, lat}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(14);
    }, [])

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    return (
        <div>
            <div className="map-header">
                <Search panTo={panTo}/>
                <Locate panTo={panTo} setNewMarker={setNewMarker}/>
            </div>
            

            <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={10}
            center={center}
            options={options}
            onClick={onMapClick}
            onLoad={onMapLoad}
            >
                {markers.map((marker) => (
                    <Marker
                        key={`${marker.lat}-${marker.lng}`}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        icon={{
                            url: markerLogo,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(15, 25),
                            scaledSize: new window.google.maps.Size(30, 40),
                        }}
                    />
                ))}

            </GoogleMap>
        </div>
    );

}

function Locate({ panTo, setNewMarker }) {
    return (
        <Button
        className="map-locate" 
        onClick={() => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    panTo({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    })
                    setNewMarker({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    })
                },
                () => null,
                {enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0}
            )
        }}
        >
            Locate Me
        </Button>
        // <div 
        // className="map-locate" 
        // onClick={() => {
        //     navigator.geolocation.getCurrentPosition(
        //         (position) => {
        //             panTo({
        //                 lat: position.coords.latitude,
        //                 lng: position.coords.longitude
        //             })
        //         },
        //         () => null,
        //     )
        // }}>
        //     Locate Me
        // </div>
    );
}

function Search({ panTo }) {
    const 
        {
            ready,
            value,
            suggestions: { status, data },
            setValue,
            clearSuggestions,
        } = usePlacesAutocomplete({
        requestOptions: 
        {
            location: { lat: () => 24.4539, lng: () => 54.3773 },
            radius: 200 * 1000,
        },
    });

    return(
        <div>
            <Combobox 
            
            onSelect={ async (address) => {
                setValue(address, false);
                clearSuggestions();
                try {
                    const results = await getGeocode({address});
                    const {lat, lng} = await getLatLng(results[0]);
                    panTo({lat, lng});
                } catch (error) {
                    
                }
            }}>
                <ComboboxInput
                className="map-combobox"
                value={value}
                onChange={(e)=> {
                    setValue(e.target.value)
                }}
                disabled={!ready}
                placeholder="Entre an address"/>
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" &&
                            data.map(({ id, description }) => (
                                <ComboboxOption key={id} value={description} />
                            ))
                        }
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    )
}